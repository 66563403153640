import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Status from './Status';
import DividerBar from './DividerBar';
import PageModal from './PageModal';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';

const statuses = ["prospects", "onboarding", "review", "create community", "create phone line", "pending guide", "create guide", "pending video", "create video", "add to content hub", "completed"];

const AssemblyLine = () => {
    const [lines, setLines] = useState([]);
    const [selectedLine, setSelectedLine] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPages, setFilteredPages] = useState([]);
    const [selectedPageIds, setSelectedPageIds] = useState([]);
    const [resetDuplicateSummaryTrigger, setResetDuplicateSummaryTrigger] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [showStatusSelect, setShowStatusSelect] = useState(false);
    const [activeColumn, setActiveColumn] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            console.log('AssemblyLine: Starting data fetch');
            const apiEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page`;

            try {
                console.log('AssemblyLine: Fetching data from:', apiEndpoint);
                const response = await fetch(apiEndpoint, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                const pages = data.items ? data.items : data;
                
                // Only keep required attributes for each page
                const simplifiedPages = pages.map(page => ({
                    name: page.name,
                    id: page.id || page.name,
                    status: page.status
                }));
                
                setLines(simplifiedPages);
            } catch (error) {
                console.error('AssemblyLine: Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        console.log('AssemblyLine: lines state updated, new length:', lines.length);
        console.log('AssemblyLine: First few lines:', lines.slice(0, 5));
    }, [lines]);

    useEffect(() => {
        console.log('AssemblyLine: filteredPages updated, new length:', filteredPages.length);
        console.log('AssemblyLine: Distribution of filteredPages by status:');
        const distribution = filteredPages.reduce((acc, page) => {
            acc[page.status] = (acc[page.status] || 0) + 1;
            return acc;
        }, {});
        console.log(distribution);
    }, [filteredPages]);

    useEffect(() => {
        console.log('AssemblyLine: Filtering pages. SearchTerm:', searchTerm);
        if (searchTerm) {
            const filtered = lines.filter(page => 
                Object.values(page).some(value => 
                    String(value).toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredPages(filtered);
        } else {
            setFilteredPages(lines);
        }
        console.log('AssemblyLine: Number of filtered pages:', filteredPages.length);
        console.log('AssemblyLine: First few filtered pages:', filteredPages.slice(0, 5));
    }, [searchTerm, lines]);

    const addNewPage = (status) => {
        const pageId = uuidv4();
        const newPage = {
            id: pageId,
            name: 'undefined',
            status: status
        };
        
        setLines(prevLines => [...prevLines, newPage]);
    
        fetch('https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newPage),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error during page creation:', error);
        });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // This function is called when a page is clicked
    const handlePageClick = (pageAttributes) => {
        setSelectedLine(pageAttributes);
    };

    const handleSaveEdit = (editedLine) => {
        const updatedLines = lines.map(line => {
            if (line.id === editedLine.id) {
                // Use the entire editedLine object for the update
                return { ...editedLine };
            }
            return line;
        });
        setLines(updatedLines);
    };    

    const handleDeletePage = async (name) => {
        try {
            const encodedName = encodeURIComponent(name);
            const apiEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page?name=${encodedName}`;
            const response = await fetch(apiEndpoint, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Assuming the backend successfully deletes the page, update the state to remove the page from the UI
            setLines(lines.filter(line => line.name !== name));
        } catch (error) {
            console.error('Error during page deletion:', error);
        }
    };

    const closeModal = () => {
        setSelectedLine(null);
    };
    
    const selectAllPagesInView = (status, pageIdsInStatus) => {
        console.log(`selectAllPagesInView called for status: ${status}`, pageIdsInStatus);
        console.log('Current selectedPageIds:', selectedPageIds);

        if (pageIdsInStatus.length === 0) {
            console.log('No pages to select in this status');
            return;
        }

        const areAllSelected = pageIdsInStatus.every(pageId => selectedPageIds.includes(pageId));
        console.log('Are all pages in this status already selected?', areAllSelected);

        if (areAllSelected) {
            // Deselect only the pages in this status
            setSelectedPageIds(currentSelectedPageIds => {
                const newSelection = currentSelectedPageIds.filter(id => !pageIdsInStatus.includes(id));
                console.log('Deselecting. New selection:', newSelection);
                return newSelection;
            });
        } else {
            // Select all pages in this status, keeping other selections intact
            setSelectedPageIds(currentSelectedPageIds => {
                const newSelection = [
                    ...currentSelectedPageIds.filter(id => !pageIdsInStatus.includes(id)),
                    ...pageIdsInStatus
                ];
                console.log('Selecting. New selection:', newSelection);
                return newSelection;
            });
        }
    };

    const moveSelectedPages = async (direction, status, onProgress) => {
        if (direction === 'update') {
            const selectedPages = lines.filter(line => selectedPageIds.includes(line.id));
            const totalPages = selectedPages.length;
            let completedPages = 0;
            let updatedLines = [...lines];
            let failures = [];

            // Increased chunk size for better throughput
            const chunkSize = 50;
            for (let i = 0; i < selectedPages.length; i += chunkSize) {
                const chunk = selectedPages.slice(i, i + chunkSize);
                
                // Create all requests for the chunk
                const requests = chunk.map(page => ({
                    promise: fetch('https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: page.name,
                            id: page.id,
                            status: status
                        })
                    }).then(response => response.json()),
                    page
                }));

                try {
                    // Use Promise.allSettled to handle partial failures
                    const results = await Promise.allSettled(requests.map(req => req.promise));
                    
                    // Process results and collect updates
                    const updates = [];
                    results.forEach((result, index) => {
                        const originalPage = requests[index].page;
                        if (result.status === 'fulfilled') {
                            const updatedPage = result.value;
                            const pageIndex = updatedLines.findIndex(line => line.id === originalPage.id);
                            if (pageIndex !== -1) {
                                updatedLines[pageIndex] = {
                                    name: updatedPage.name,
                                    id: updatedPage.id,
                                    status: updatedPage.status
                                };
                                updates.push(updatedLines[pageIndex]);
                            }
                        } else {
                            failures.push({
                                page: originalPage,
                                error: result.reason
                            });
                            console.error(`Failed to update page ${originalPage.name}:`, result.reason);
                        }
                    });

                    // Batch update state once per chunk
                    if (updates.length > 0) {
                        setLines([...updatedLines]);
                    }

                    // Update progress
                    completedPages += chunk.length;
                    if (onProgress) {
                        onProgress((completedPages / totalPages) * 100);
                    }
                } catch (error) {
                    console.error('Error processing batch:', error);
                    failures.push(...chunk.map(page => ({
                        page,
                        error: error
                    })));
                }
            }

            // Report any failures after all batches are processed
            if (failures.length > 0) {
                console.error(`Failed to update ${failures.length} pages:`, failures);
            }

            return updatedLines;
        }

        // Original moveSelectedPages logic for left/right movement
        const pagesInCurrentStatus = lines.filter(line => line.status === status);
        const selectedPagesInCurrentStatus = pagesInCurrentStatus.filter(page => selectedPageIds.includes(page.id));
    
        // Determine the new status based on the direction
        const currentIndex = statuses.indexOf(status);
        const newIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
    
        // Validate movement is possible
        if ((direction === 'left' && currentIndex <= 0) || 
            (direction === 'right' && currentIndex >= statuses.length - 1)) {
            return;
        }

        const newStatus = statuses[newIndex];
    
        // Update local state immediately for responsive UI
        const updatedLines = lines.map(line => 
            selectedPagesInCurrentStatus.find(page => page.id === line.id)
                ? { ...line, status: newStatus }
                : line
        );
        setLines(updatedLines);
    
        // Prepare batch updates for backend
        const movedLines = updatedLines.filter(line => selectedPageIds.includes(line.id));
        const batchSize = 50;
        
        // Process updates in parallel batches
        for (let i = 0; i < movedLines.length; i += batchSize) {
            const batch = movedLines.slice(i, i + batchSize);
            
            // Create all promises for the batch
            const promises = batch.map(page => {
                // Only send required attributes
                const updateData = {
                    name: page.name,
                    id: page.id,
                    status: newStatus
                };
                
                return fetch('https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updateData)
                }).then(response => {
                    if (!response.ok) {
                        throw new Error(`Failed to update ${page.name}`);
                    }
                    return response.json();
                }).then(updatedPage => {
                    // Only keep the attributes we need from the response
                    return {
                        name: updatedPage.name,
                        id: updatedPage.id,
                        status: updatedPage.status
                    };
                });
            });

            try {
                const results = await Promise.allSettled(promises);
                
                // Process results and collect updates
                const updates = [];
                results.forEach((result, index) => {
                    const originalPage = batch[index];
                    if (result.status === 'fulfilled') {
                        const updatedPage = result.value;
                        const pageIndex = updatedLines.findIndex(line => line.id === originalPage.id);
                        if (pageIndex !== -1) {
                            updatedLines[pageIndex] = {
                                name: updatedPage.name,
                                id: updatedPage.id,
                                status: updatedPage.status
                            };
                            updates.push(updatedLines[pageIndex]);
                        }
                    } else {
                        console.error(`Failed to update page ${originalPage.name}:`, result.reason);
                    }
                });

                // Batch update state once per chunk
                if (updates.length > 0) {
                    setLines([...updatedLines]);
                }
            } catch (error) {
                console.error('Error updating batch:', error);
            }
        }

        // Update duplicate summary trigger after all updates
        setResetDuplicateSummaryTrigger(true);
    };
    
    const onDragEnd = async (result) => {
        const { destination, source, draggableId } = result;
        if (!destination) return;
    
        // Start with an empty array for pages to update
        let pagesToUpdate = [];
    
        // If multiple pages are selected, prepare to update all selected pages
        if (selectedPageIds.length > 1 && selectedPageIds.includes(draggableId)) {
            pagesToUpdate = lines.filter(line => selectedPageIds.includes(line.id));
        } else {
            // Otherwise, prepare to update just the dragged page
            const page = lines.find(line => line.id === draggableId);
            if (page) {
                pagesToUpdate = [page];
            }
        }
    
        // Update the local state to reflect the new order and status
        const newLines = Array.from(lines);
        pagesToUpdate.forEach(page => {
            const pageIndex = newLines.findIndex(line => line.id === page.id);
            if (pageIndex !== -1) {
                newLines.splice(pageIndex, 1); // Remove from current position
            }
            page.status = destination.droppableId; // Update status
            newLines.splice(destination.index, 0, page); // Insert at new position
        });
    
        setLines(newLines);
        setSelectedPageIds([]); // Clear selection after moving
    
        // Update the status for each page in the backend
        for (const page of pagesToUpdate) {
            const pageData = { status: page.status };
            const apiEndpoint = `https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page?name=${encodeURIComponent(page.name)}`;
    
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(pageData),
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                console.log('Page status updated successfully', await response.json());
            } catch (error) {
                console.error('Error during page status update:', error);
            }
        }
        setResetDuplicateSummaryTrigger(true);
    };

    const handleStatusUpdate = async () => {
        if (!selectedStatus || selectedPageIds.length === 0) return;

        console.log(`Updating ${selectedPageIds.length} pages to status: ${selectedStatus}`);
        const selectedPages = lines.filter(line => selectedPageIds.includes(line.id));

        try {
            for (const page of selectedPages) {
                const response = await fetch('https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...page,
                        status: selectedStatus
                    })
                });

                if (!response.ok) {
                    throw new Error(`Failed to update ${page.name}: ${await response.text()}`);
                }
            }

            // Update local state
            const updatedLines = lines.map(line =>
                selectedPageIds.includes(line.id)
                    ? { ...line, status: selectedStatus }
                    : line
            );
            setLines(updatedLines);
            
            // Reset selection state
            setSelectedPageIds([]);
            setSelectedStatus('');
            setShowStatusSelect(false);
            setActiveColumn(null);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    // This function should be passed down to Status and called after the effect runs
    const onResetDuplicateSummaryDone = () => {
        console.log('Resetting resetDuplicateSummaryTrigger to false');
        setResetDuplicateSummaryTrigger(false);
    };

    return (
        <>
            <DividerBar onSearchChange={handleSearchChange} />
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="assembly-line">
                    {statuses.map(status => (
                        <Droppable droppableId={status} key={status}>
                            {(provided, snapshot) => {
                                const linesForStatus = filteredPages.filter(line => line.status === status);
                                console.log(`AssemblyLine: Status ${status}, number of lines:`, linesForStatus.length);
                                return (
                                    <div 
                                        ref={provided.innerRef} 
                                        {...provided.droppableProps}
                                        style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'white' }}
                                        className="status-box"
                                    >
                                        <Status 
                                            title={status} 
                                            lines={linesForStatus}
                                            addNewPage={() => addNewPage(status)} 
                                            onSelectAll={selectAllPagesInView}
                                            onPageClick={handlePageClick}
                                            selectedPageIds={selectedPageIds}
                                            moveSelectedPages={(direction, newStatus, onProgress) => moveSelectedPages(direction, newStatus, onProgress)}
                                            resetDuplicateSummaryTrigger={resetDuplicateSummaryTrigger}
                                            onResetDuplicateSummaryDone={onResetDuplicateSummaryDone}
                                        />
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    ))}
                    <PageModal line={selectedLine} onClose={closeModal} onSave={handleSaveEdit} onDelete={handleDeletePage} />
                </div>
            </DragDropContext>
        </>
    );
};

export default AssemblyLine;